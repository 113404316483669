var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"app"},[_c('v-header'),_c('v-title',{staticClass:"m-1-top gutter",attrs:{"src":require('@/assets/images/logo-new.svg'),"ratio":1606 / 163}}),_c('v-stripes',{staticClass:"gutter app_stripes_1",attrs:{"title":'spring 22 collection',"subTitle":"By Anthony Vaccarello"}}),_c('v-cover',{staticClass:"app__figure_hp gutter",attrs:{"lazy":'auto',"src":require('@/assets/images/SAINT_LAURENT_SPRING22_04_HR_k2nlbx/SAINT_LAURENT_SPRING22_04_HR_k2nlbx_c_scale,w_1600.jpg'),"srcset":("\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22_04_HR_k2nlbx/SAINT_LAURENT_SPRING22_04_HR_k2nlbx_c_scale,w_800.jpg')) + " 800w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22_04_HR_k2nlbx/SAINT_LAURENT_SPRING22_04_HR_k2nlbx_c_scale,w_1235.jpg')) + " 1235w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22_04_HR_k2nlbx/SAINT_LAURENT_SPRING22_04_HR_k2nlbx_c_scale,w_1560.jpg')) + " 1496w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22_04_HR_k2nlbx/SAINT_LAURENT_SPRING22_04_HR_k2nlbx_c_scale,w_1600.jpg')) + " 1600w,\n    "),"sizes":"(max-width: 1600px) 100vw, 1600px","mediaLink":"https://www.ysl.com/en-en/search?cgid=w-spring22-looks&look=2022_1_23_037","ratio":1600 / 2133}}),_c('v-stripes',{staticClass:"app_stripes_2 gutter",attrs:{"title":'Photographed by David Sims',"subTitle":"In Paris, December 2021"}}),_c('v-slideshow',{staticClass:"app__slideshow_dyptique gutter",attrs:{"wrap":"","demi":"","slides":[
      {
        src: require('@/assets/images/SAINT_LAURENT_SPRING22_11_HR_ehgi4u/SAINT_LAURENT_SPRING22_11_HR_ehgi4u_c_scale,w_1600.jpg'),
        srcset: ("\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_11_HR_ehgi4u/SAINT_LAURENT_SPRING22_11_HR_ehgi4u_c_scale,w_400.jpg')) + " 800w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_11_HR_ehgi4u/SAINT_LAURENT_SPRING22_11_HR_ehgi4u_c_scale,w_930.jpg')) + " 930w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_11_HR_ehgi4u/SAINT_LAURENT_SPRING22_11_HR_ehgi4u_c_scale,w_1292.jpg')) + " 1292w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_11_HR_ehgi4u/SAINT_LAURENT_SPRING22_11_HR_ehgi4u_c_scale,w_1600.jpg')) + " 1600w\n        "),
        sizes: '(max-width: 1600px) 100vw, 1600px',
        mediaLink: 'https://www.ysl.com/en-en/search?cgid=w-spring22-looks&look=2022_1_23_001'
      },
      {
        src: require('@/assets/images/SAINT_LAURENT_SPRING22_12_HR_kpvxti/SAINT_LAURENT_SPRING22_12_HR_kpvxti_c_scale,w_1600.jpg'),
        srcset: ("\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_12_HR_kpvxti/SAINT_LAURENT_SPRING22_12_HR_kpvxti_c_scale,w_400.jpg')) + " 800w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_12_HR_kpvxti/SAINT_LAURENT_SPRING22_12_HR_kpvxti_c_scale,w_1048.jpg')) + " 1048w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_12_HR_kpvxti/SAINT_LAURENT_SPRING22_12_HR_kpvxti_c_scale,w_1446.jpg')) + " 1446w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_12_HR_kpvxti/SAINT_LAURENT_SPRING22_12_HR_kpvxti_c_scale,w_1600.jpg')) + " 1600w\n        "),
        sizes: '(max-width: 1600px) 100vw, 1600px',
        mediaLink: 'https://www.ysl.com/en-en/search?cgid=w-spring22-looks&look=2022_1_23_001'
      }
    ],"showLegendPos":0}}),_c('v-cover',{staticClass:"app__cover_video_1",attrs:{"src":"https://storage.googleapis.com/spring22/YSL_SS22_MENS_04_15SEC_16_9.mp4","poster":require('@/assets/videos/YSL_SS22_MENS_04_15SEC_16_9.jpg'),"ratio":16 / 9}}),_c('v-cover-slideshow',{attrs:{"cover":require('@/assets/images/nick_desktop_1.png'),"coverRatio":1024 / 751,"customStyles":'margin-top:0',"customClassesSlides":'contain',"legend":'Red Right Hand — Nick Cave & The Bad Seeds',"slides":[
      {
        src: require('@/assets/images/nick_mobile_1.png'),
      },
      {
        src: require('@/assets/images/nick_mobile_2.png'),
      }
    ],"slidesRatio":375 / 511}}),_c('v-cover',{staticClass:"app__figure_lady gutter",attrs:{"gutters":"","src":require('@/assets/images/SAINT_LAURENT_SPRING22-PT2_03_HR_wif85e/SAINT_LAURENT_SPRING22-PT2_03_HR_wif85e_c_scale,w_1600.jpg'),"srcset":("\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22-PT2_03_HR_wif85e/SAINT_LAURENT_SPRING22-PT2_03_HR_wif85e_c_scale,w_400.jpg')) + " 400w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22-PT2_03_HR_wif85e/SAINT_LAURENT_SPRING22-PT2_03_HR_wif85e_c_scale,w_1250.jpg')) + " 1250w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22-PT2_03_HR_wif85e/SAINT_LAURENT_SPRING22-PT2_03_HR_wif85e_c_scale,w_1600.jpg')) + " 1600w,\n    "),"sizes":"(max-width: 1600px) 100vw, 1600px","ratio":1600 / 1200}}),_c('v-cover',{staticClass:"app__figure_chair gutter",attrs:{"gutters":"","src":require('@/assets/images/SAINT_LAURENT_SPRING22_03_HR_q03i5y/SAINT_LAURENT_SPRING22_03_HR_q03i5y_c_scale,w_1600.jpg'),"srcset":("\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22_03_HR_q03i5y/SAINT_LAURENT_SPRING22_03_HR_q03i5y_c_scale,w_400.jpg')) + " 400w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22_03_HR_q03i5y/SAINT_LAURENT_SPRING22_03_HR_q03i5y_c_scale,w_896.jpg')) + " 896w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22_03_HR_q03i5y/SAINT_LAURENT_SPRING22_03_HR_q03i5y_c_scale,w_1205.jpg')) + " 1205w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22_03_HR_q03i5y/SAINT_LAURENT_SPRING22_03_HR_q03i5y_c_scale,w_1600.jpg')) + " 1600w,\n    "),"sizes":"(max-width: 1600px) 100vw, 1600px","ratio":1600 / 2133,"mediaLink":"https://www.ysl.com/en-en/search?cgid=w-spring22-looks&look=2022_1_23_059"}}),_c('v-slideshow',{staticClass:"app__slideshow_dyptique gutter",attrs:{"wrap":"","demi":"","slides":[
      {
        src: require('@/assets/images/SAINT_LAURENT_SS22_22_HR_fh96q2/SAINT_LAURENT_SS22_22_HR_fh96q2_c_scale,w_1600.jpg'),
        srcset: ("\n          " + (require('@/assets/images/SAINT_LAURENT_SS22_22_HR_fh96q2/SAINT_LAURENT_SS22_22_HR_fh96q2_c_scale,w_400.jpg')) + " 400w,\n          " + (require('@/assets/images/SAINT_LAURENT_SS22_22_HR_fh96q2/SAINT_LAURENT_SS22_22_HR_fh96q2_c_scale,w_896.jpg')) + " 896w,\n          " + (require('@/assets/images/SAINT_LAURENT_SS22_22_HR_fh96q2/SAINT_LAURENT_SS22_22_HR_fh96q2_c_scale,w_1234.jpg')) + " 1234w,\n          " + (require('@/assets/images/SAINT_LAURENT_SS22_22_HR_fh96q2/SAINT_LAURENT_SS22_22_HR_fh96q2_c_scale,w_1600.jpg')) + " 1600w\n        "),
        sizes: '(max-width: 1600px) 100vw, 1600px',
        mediaLink: 'https://www.ysl.com/en-en/search?cgid=men-spring-summer-22-looks&look=2022_1_2B_049'
      },
      {
        src: require('@/assets/images/SAINT_LAURENT_SPRING22_09_HR_j6qrqm/SAINT_LAURENT_SPRING22_09_HR_j6qrqm_c_scale,w_1600.jpg'),
        srcset: ("\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_09_HR_j6qrqm/SAINT_LAURENT_SPRING22_09_HR_j6qrqm_c_scale,w_400.jpg')) + " 400w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_09_HR_j6qrqm/SAINT_LAURENT_SPRING22_09_HR_j6qrqm_c_scale,w_924.jpg')) + " 924w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_09_HR_j6qrqm/SAINT_LAURENT_SPRING22_09_HR_j6qrqm_c_scale,w_1286.jpg')) + " 1286w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_09_HR_j6qrqm/SAINT_LAURENT_SPRING22_09_HR_j6qrqm_c_scale,w_1600.jpg')) + " 1600w\n        "),
        sizes: '(max-width: 1600px) 100vw, 1600px',
        mediaLink: 'https://www.ysl.com/en-en/search?cgid=w-spring22-looks&look=2022_1_23_005'
      }
    ]}}),_c('v-cover-slideshow',{attrs:{"cover":require('@/assets/images/nick_desktop_2.png'),"coverRatio":1024 / 751,"customStyles":'margin-bottom:0',"customClassesSlides":'contain bg-black',"legend":'Red Right Hand — Nick Cave & The Bad Seeds',"legendLeft":false,"slides":[
      {
        src: require('@/assets/images/nick_mobile_3.png'),
      },
      {
        src: require('@/assets/images/nick_mobile_4.png'),
      }
    ],"slidesRatio":375 / 511,"showLegendPos":1}}),_c('v-cover',{staticClass:"no-margin-top",attrs:{"src":"https://storage.googleapis.com/spring22/YSL_SS22_WOMENS_01_15SEC_16_9.mp4","poster":require('@/assets/videos/YSL_SS22_WOMENS_01_15SEC_16_9.jpg'),"ratio":16 / 9}}),_c('v-slideshow',{staticClass:"app__slideshow_dyptique gutter",attrs:{"wrap":"","demi":"","slides":[
      {
        src: require('@/assets/images/SAINT_LAURENT_SS22_21_HR_lphxqu/SAINT_LAURENT_SS22_21_HR_lphxqu_c_scale,w_1600.jpg'),
        srcset: ("\n          " + (require('@/assets/images/SAINT_LAURENT_SS22_21_HR_lphxqu/SAINT_LAURENT_SS22_21_HR_lphxqu_c_scale,w_400.jpg')) + " 400w,\n          " + (require('@/assets/images/SAINT_LAURENT_SS22_21_HR_lphxqu/SAINT_LAURENT_SS22_21_HR_lphxqu_c_scale,w_972.jpg')) + " 972w,\n          " + (require('@/assets/images/SAINT_LAURENT_SS22_21_HR_lphxqu/SAINT_LAURENT_SS22_21_HR_lphxqu_c_scale,w_1345.jpg')) + " 1345w,\n          " + (require('@/assets/images/SAINT_LAURENT_SS22_21_HR_lphxqu/SAINT_LAURENT_SS22_21_HR_lphxqu_c_scale,w_1600.jpg')) + " 1600w\n        "),
        sizes: '(max-width: 1600px) 100vw, 1600px',
        mediaLink: 'https://www.ysl.com/en-en/search?cgid=men-spring-summer-22-looks&look=2022_1_2B_016'
      },
      {
        src: require('@/assets/images/SAINT_LAURENT_SPRING22_08_HR_zsvnah/SAINT_LAURENT_SPRING22_08_HR_zsvnah_c_scale,w_1600.jpg'),
        srcset: ("\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_08_HR_zsvnah/SAINT_LAURENT_SPRING22_08_HR_zsvnah_c_scale,w_400.jpg')) + " 400w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_08_HR_zsvnah/SAINT_LAURENT_SPRING22_08_HR_zsvnah_c_scale,w_998.jpg')) + " 998w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_08_HR_zsvnah/SAINT_LAURENT_SPRING22_08_HR_zsvnah_c_scale,w_1372.jpg')) + " 1372w,\n          " + (require('@/assets/images/SAINT_LAURENT_SPRING22_08_HR_zsvnah/SAINT_LAURENT_SPRING22_08_HR_zsvnah_c_scale,w_1600.jpg')) + " 1600w\n        "),
        sizes: '(max-width: 1600px) 100vw, 1600px',
        mediaLink: 'https://www.ysl.com/en-en/satchel-and-bucket-bags/le-maillon-hook-bucket-bag-in-supple-leather-686310AAAJK1000.html'
      }
    ]}}),_c('v-cover',{staticClass:"app__figure_blackshirt gutter",attrs:{"gutters":"","src":require('@/assets/images/SAINT_LAURENT_SS22_16_HR_ei8qg4/SAINT_LAURENT_SS22_16_HR_ei8qg4_c_scale,w_1600.jpg'),"srcset":("\n      " + (require('@/assets/images/SAINT_LAURENT_SS22_16_HR_ei8qg4/SAINT_LAURENT_SS22_16_HR_ei8qg4_c_scale,w_400.jpg')) + " 400w,\n      " + (require('@/assets/images/SAINT_LAURENT_SS22_16_HR_ei8qg4/SAINT_LAURENT_SS22_16_HR_ei8qg4_c_scale,w_960.jpg')) + " 960w,\n      " + (require('@/assets/images/SAINT_LAURENT_SS22_16_HR_ei8qg4/SAINT_LAURENT_SS22_16_HR_ei8qg4_c_scale,w_1374.jpg')) + " 1374w,\n      " + (require('@/assets/images/SAINT_LAURENT_SS22_16_HR_ei8qg4/SAINT_LAURENT_SS22_16_HR_ei8qg4_c_scale,w_1600.jpg')) + " 1600w,\n    "),"sizes":"(max-width: 1600px) 100vw, 1600px","mediaLink":"https://www.ysl.com/en-en/search?cgid=men-spring-summer-22-looks&look=2022_1_2B_005","ratio":1600 / 2133}}),_c('v-cover',{staticClass:"app__figure_man gutter",attrs:{"gutters":"","src":require('@/assets/images/SAINT_LAURENT_SPRING22-PT2_07_HR_wy4axl/SAINT_LAURENT_SPRING22-PT2_07_HR_wy4axl_c_scale,w_1600.jpg'),"srcset":("\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22-PT2_07_HR_wy4axl/SAINT_LAURENT_SPRING22-PT2_07_HR_wy4axl_c_scale,w_400.jpg')) + " 400w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22-PT2_07_HR_wy4axl/SAINT_LAURENT_SPRING22-PT2_07_HR_wy4axl_c_scale,w_1144.jpg')) + " 1144w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22-PT2_07_HR_wy4axl/SAINT_LAURENT_SPRING22-PT2_07_HR_wy4axl_c_scale,w_1588.jpg')) + " 1588w,\n      " + (require('@/assets/images/SAINT_LAURENT_SPRING22-PT2_07_HR_wy4axl/SAINT_LAURENT_SPRING22-PT2_07_HR_wy4axl_c_scale,w_1600.jpg')) + " 1600w,\n    "),"sizes":"(max-width: 1600px) 100vw, 1600px","ratio":1600 / 1200,"mediaLink":"https://www.ysl.com/en-en/search?cgid=men-spring-summer-22-looks&look=2022_1_2B_056"}}),_c('v-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }